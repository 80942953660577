nav.icon-nav,
nav.icon-nav-open,
div.icon-nav-item {
    background: #fff;
}

nav.icon-nav {
    width: 50px;
    transition: width linear 0.1s;
}

nav.icon-nav-open {
    width: 250px;
    transition: width linear 0.1s;
}

nav.icon-nav,
nav.icon-nav-open {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 101;
}

    nav.icon-nav div.icon-nav-item,
    nav.icon-nav-open div.icon-nav-item {
        position: absolute;
        float: left;
        padding: 0;
        margin: 0 0 0 0;
        clear: both;
        list-style: none;
        width: 100%;
    }

.menu-logo {
    margin-left: 22px;
    margin-top: -40px;
}

div.icon-nav-item div.icon-nav-item-link {
    position: relative;
    display: block;
    line-height: 50px;
    height: 50px;
    white-space: nowrap;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    z-index: 94;
    border-bottom: 1px solid #c2d2df;
}

div.icon-nav-item-link div.icon-nav-item-link-container div.icon-nav-item-link-icon {
    display: block;
    float: left;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center center;
    line-height: 50px;
    text-align: center;
    margin-right: -6px;
    font-size: 25px;
    color: white;
}

.icon-nav-item-link-icon-dark {
    color: #3c3c3b;
}

div.icon-nav-item-link div.icon-nav-item-link-container.icon-nav-item-link-content:hover div,
div.icon-nav-item-link div.icon-nav-item-link-container.icon-nav-item-link-content.active div {
    background-color: #fbf5e1;
    color: 3c3c3b;
    height: 49px;
}

    div.icon-nav-item-link div.icon-nav-item-link-container.icon-nav-item-link-content:hover div.icon-nav-item-link-icon-dark,
    div.icon-nav-item-link div.icon-nav-item-link-container.icon-nav-item-link-content.active div.icon-nav-item-link-icon-dark {
        background-color: #fbf5e1;
        color: #3c3c3b;
    }

nav.icon-nav div.icon-nav-item-link div.icon-nav-item-link-container div.icon-nav-item-link-label-hide {
    opacity: 0;
    width: 0;
    padding-right: 0;
    font-size: 0;
}

nav.icon-nav-open div.icon-nav-item-link div.icon-nav-item-link-container div.icon-nav-item-link-label-hide {
    transition: inherit;
}

div.icon-nav-item-link div.icon-nav-item-link-container div.icon-nav-item-link-label-hide,
div.icon-nav-item-link div.icon-nav-item-link-container div.icon-nav-item-link-label-show {
    position: absolute;
    display: block;
    opacity: 1;
    transition: linear 0.1s;
    margin-left: 50px;
    padding-left: 15px;
    padding-right: 10px;
    width: 200px;
    height: 50px;
    text-transform: uppercase;
    color: black;
}

div.icon-nav-item-link .icon-nav-item-link-menu {
    position: relative;
    display: block;
    line-height: 50px;
    height: 50px;
    white-space: nowrap;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}

div.menu-background-header {
    position: absolute;
    z-index: 93;
    height: 100px;
    width: 50px;
    background: #E0C5A2;
}

div.menu-background {
    position: absolute;
    z-index: 92;
    height: 100%;
    width: 50px;
    background: #ffffff;
}

.menu-toggle {
    position: relative;
    display: block;
    line-height: 50px;
    height: 100px;
    white-space: nowrap;
    text-decoration: none;
    font-size: 25px;
    cursor: pointer;
    padding-left: 7px;
    padding-top: 50px;
    z-index: 94;
    color: #3c3c3b;
    background: #EFA220;
}

    .menu-toggle a {
        cursor: pointer;
        color: black;
    }

.icon-menu-1 {
    color: white;
}

.icon-menu-1 img {
    transition: linear 0.1s;
    margin-left: 22px;
}

.language-panel {
    margin-top: 10px;
    margin-left: 22px;
}

.menu-flag-icons {
    cursor: pointer;
}

.icon-uk-flag {
    margin-left: 10px !important;
}
